import React, { useEffect, useState } from 'react'
import {
  Text,
  Break,
  Field,
  Panel,
  Form,
  FormAction,
  Wrapper,
  Sided,
  Button,
  Icon,
  Checkbox,
  Scroll,
  Divider,
  VSided,
  FeatureFlag,
} from 'views/components'
import './style.scss'
import { ModalProps, Modal, DefaultModalService } from 'views/components/Modal'
import { categoryFormSchema } from './categoryFormSchema'
import { CreateCategoryRequest } from 'domains/management/category/models'
import { categoryService, commonService, productServiceV2 } from 'injectors'
import DeleteConfirmationModal from '../Modal/deleteConfirmation'
import { trimDuplicateSpace } from 'utilities/strings'
import selector from '../selector'
import { useSelector } from 'react-redux'
import { Debouncer } from 'utilities/debounce'
import { featureCheck } from 'config'
import { arrayUnique } from 'utilities/arrayUnique'
import { useLingui } from '@lingui/react'
import { t, Trans } from '@lingui/macro'

interface Props extends ModalProps {
  id?: string
  name?: string
  action?: string
}

const CategoryForm: React.FC<Props> = props => {
  const categoryName = (props && props.name) || ''
  const currentCategoryId = (props && props.id) || ''
  const { products } = useSelector(selector)
  const [productIds, setProductIds] = useState([])
  const [unassignedProductIds, setUnassignedProductIds] = useState([])
  const debouncer = new Debouncer()
  const { i18n } = useLingui()

  useEffect(() => {
    return () => {
      productServiceV2.getProducts()
    }
  }, [])

  useEffect(() => {
    const initProductIds =
      products &&
      products.filter(x => x.category && x.category.id === currentCategoryId)
    setProductIds(arrayUnique(productIds.concat(initProductIds.map(x => x.id))))
  }, [products])

  const handleSearchProductByName = (name: string) => {
    debouncer.debounce(() => {
      productServiceV2.getProducts({ name })
      return null
    }, 500)
  }

  const deleteCategory = async (id: string, name: string) => {
    try {
      const isConfirmed = await DefaultModalService.open(
        DeleteConfirmationModal,
        { name: name }
      )
      if (isConfirmed) {
        categoryService.deleteCategory(id)
        commonService.setAlert({
          type: 'netral',
          message: i18n._(t`Kategori berhasil dihapus`),
        })
        props.dismiss()
      }
    } catch (error) {
      commonService.setAlert({
        type: 'error',
        message: error.message,
      })
    }
  }

  const submit = async (actions: FormAction<CreateCategoryRequest>) => {
    try {
      const values = actions.getValues()
      const spaceTrimmedValue = {
        name: trimDuplicateSpace(values.name),
        productIds: productIds,
        unassignedProductIds: unassignedProductIds,
      }
      if (props.action === 'update') {
        await categoryService.updateSingleCategory(
          {
            ...spaceTrimmedValue,
          },
          props.id
        )
        commonService.setAlert({
          type: 'success',
          message: i18n._(t`Kategori berhasil diubah`),
        })
      } else {
        await categoryService.createSingleCategory(spaceTrimmedValue)
        commonService.setAlert({
          type: 'success',
          message: i18n._(t`Kategori berhasil dibuat`),
        })
      }
      productServiceV2.getProducts({ limit: 10 })
      props.dismiss()
    } catch (error) {
      if (error.code === 'already_exists') {
        actions.setFieldError('name', i18n._(t`Nama kategori sudah terdaftar`))
      }
      commonService.setAlert({
        type: 'error',
        message: error.message,
      })
    }
  }

  const renderDeleteCategoryButton = () =>
    props.action === 'update' && (
      <Trans>
        <Text.Heading
          h={6}
          color='red'
          onClick={() => deleteCategory(props.id, props.name)}
          align='center'
        >
          Hapus Kategori Produk
        </Text.Heading>
      </Trans>
    )

  const renderCategoryFieldCard = () => (
    <Panel.Card padding={16}>
      <Trans>
        <Text.Heading h={5}>Detail Kategori</Text.Heading>
      </Trans>
      <Divider />
      <Field.Text
        label={i18n._(t`Nama Kategori`)}
        name='name'
        placeholder={i18n._(t`Contoh: Main Course`)}
      />
    </Panel.Card>
  )

  const assignCategoryToProductListCard = () => (
    <FeatureFlag
      featureCheck={featureCheck.management.categoryCreationWithProduct}
    >
      <Panel.Card padding={16}>
        <Trans>
          <Text.Heading h={5}>Pilih Produk</Text.Heading>
        </Trans>
        <Break height={10} />
        <Field.Text
          className='product-search-input'
          placeholder={i18n._(t`Cari produk`)}
          name='product-search'
          onChange={value => handleSearchProductByName(value)}
          prefix={<Icon.Search color='black' />}
        />
        {renderProductList()}
      </Panel.Card>
    </FeatureFlag>
  )

  const handleAssignCategoryToProduct = (
    productId: string,
    categoryId?: string
  ) => {
    let newArr = productIds
    if (productIds.includes(productId)) {
      newArr = productIds.filter(x => x !== productId)
      if (
        currentCategoryId === categoryId &&
        !unassignedProductIds.includes(productId)
      ) {
        const unassignArr = [...unassignedProductIds, productId]
        setUnassignedProductIds(unassignArr)
      }
    } else {
      newArr = [...productIds, productId]
      if (unassignedProductIds.includes(productId)) {
        const unassignArr = unassignedProductIds.filter(x => x !== productId)
        setUnassignedProductIds(unassignArr)
      }
    }
    setProductIds(newArr)
  }

  const handleLoadMoreProduct = () => {
    if (products.length !== 0) {
      productServiceV2.loadMore()
    }
  }

  const renderProductList = () => (
    <Scroll
      className='category-form-product-list'
      onBottom={() => handleLoadMoreProduct()}
    >
      {products &&
        products.map(item => {
          return (
            <Wrapper padding='16px 0px 0px'>
              <Sided.Sided gutter={16} justifyContent='center' justify='top'>
                <Sided.Remaining>
                  <Text.Span
                    dangerousContent={item.name}
                    color='black'
                    size={14}
                  ></Text.Span>
                </Sided.Remaining>
                <Sided.Remaining align='right'>
                  <Text.Span size={14} color='semiGray'>
                    {item.category ? item.category.name : ''}
                  </Text.Span>
                </Sided.Remaining>
                <Sided.Fixed>
                  {
                    <Checkbox
                      checked={productIds.includes(item.id)}
                      onChange={() => {
                        handleAssignCategoryToProduct(
                          item.id,
                          item.category?.id
                        )
                      }}
                    />
                  }
                </Sided.Fixed>
              </Sided.Sided>
              <Divider margin={16} />
            </Wrapper>
          )
        })}
    </Scroll>
  )

  return (
    <Modal
      dismiss={props.dismiss}
      type='full'
      canDismissOutside={false}
      className='modal-category-new'
    >
      <Form.Form
        height='100%'
        initialValues={
          {
            name: categoryName,
          } as CreateCategoryRequest
        }
        validationSchema={categoryFormSchema}
        onSubmit={submit}
        render={(actions: FormAction<CreateCategoryRequest>) => {
          return (
            <VSided.VSided>
              <VSided.Remaining>
                <Wrapper width='480px' isCenter padding='36px 0px'>
                  <Text.Heading h={4}>
                    {props.action === 'update'
                      ? i18n._(t`Ubah Kategori Produk`)
                      : i18n._(t`Tambah Kategori Produk`)}
                  </Text.Heading>
                  <Break height={24} />
                  {renderCategoryFieldCard()}
                  <Break height={16} />
                  {assignCategoryToProductListCard()}
                </Wrapper>
                {renderDeleteCategoryButton()}
              </VSided.Remaining>
              <VSided.Fixed>
                <Wrapper
                  shadow='0 -2px 4px 0 rgba(0, 0, 0, 0.2)'
                  width='100%'
                  padding='16px'
                  backgroundColor='white'
                >
                  <Wrapper width='480px' isCenter>
                    <Sided.Sided justify='center' gutter={16}>
                      <Sided.Remaining>
                        <Button
                          size='large'
                          theme='netral'
                          type='button'
                          label={i18n._(t`Batal`)}
                          fullW={true}
                          onClick={() => props.dismiss()}
                        />
                      </Sided.Remaining>
                      <Sided.Remaining>
                        <Button
                          size='large'
                          theme='primary'
                          disabled={!actions.isValid()}
                          type='submit'
                          label={i18n._(t`Simpan`)}
                          fullW={true}
                        />
                      </Sided.Remaining>
                    </Sided.Sided>
                  </Wrapper>
                </Wrapper>
              </VSided.Fixed>
            </VSided.VSided>
          )
        }}
      />
    </Modal>
  )
}

export default CategoryForm
